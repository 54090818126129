import '../App.css';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';



function council() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

      <p><span class="style3">The NTBA</span> is a not-for-profit association that supports and promotes bridge in the Northern Territory on behalf of its members - the three affiliated NT bridge clubs.</p>

<p align="justify">The affiliated clubs direct the association through their membership of the Committee.</p>

<p align="justify">The Association's policy is not to compete with its member clubs  - rather it supports beginner and other lessons. </p>

<p align="justify">Through its Tournament Committee, it organises Championship-level competitions open to all NT playing members (and visitors). It also convenes the annual Territory Gold Bridge Festival, held alternately in Darwin and Alice Springs.</p>



<table width="71%" border="0" align="left" bgcolor="#FFFF99">

  <tr>

    <td width="35%"><span class="style2">Committee members:</span></td>

    <td width="65%"><b>Pam Nunn   (President)</b></td>

    </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Eileen Boocock  (Alice Springs Bridge Club)</b></td>

    </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Marion Davey  (Arafura Bridge Club)</b></td>

    </tr>

  

  <tr>

    <td>&nbsp;</td>

    <td><b>Bev Luke (Darwin Bridge Club)</b></td>

    </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Toni Vine-Bromley (Secretary, ex officio member)</b></td>

    </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Sal Costanzo (Treasurer) </b></td>

    </tr>

	

</table>


      </div>


      
      <Footer/>
    </div>
  );
}

export default council;