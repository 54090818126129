
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Main from './pages/main';
import Where from './pages/where';
import How from './pages/how';
import TGBF2022 from './pages/tgbfResults2022';
import TGBF2023 from './pages/tgbfResults2023';
import Role from './pages/role';
import Notices from './pages/notices';
import Committee from './pages/committee';
import TC from './pages/tc';
import MC from './pages/mc';


function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main/>} exact/>
          <Route path='/where' element={<Where/>} />
          <Route path='/how' element={<How/>} />
          <Route path='/tgbf2022' element={<TGBF2022/>} />
          <Route path='/tgbf2023' element={<TGBF2023/>} />
          <Route path='/role' element={<Role/>} />
          <Route path='/notices' element={<Notices/>} />
          <Route path='/committee' element={<Committee/>} />
          <Route path='/tc' element={<TC/>} />
          <Route path='/mc' element={<MC/>} />
        </Routes>
      </BrowserRouter>

  );
}

export default App;
