import '../App.css';
import {Link} from 'react-router-dom';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import logo from '../assets/images/tgbflogotrans.gif';
import I01 from '../assets/images/01.JPG';
import I02 from '../assets/images/02.JPG';
import I03 from '../assets/images/03.JPG';
import I04 from '../assets/images/04.JPG';
import I05 from '../assets/images/05.JPG';
import I06 from '../assets/images/06.JPG';
import I07 from '../assets/images/07.JPG';
import I08 from '../assets/images/08.JPG';
import I09 from '../assets/images/09.JPG';
import I10 from '../assets/images/10.JPG';
import I11 from '../assets/images/11.JPG';
import I12 from '../assets/images/12.JPG';
import I13 from '../assets/images/13.JPG';
import I14 from '../assets/images/14.JPG';
import I15 from '../assets/images/15.JPG';
import I16 from '../assets/images/16.JPG';
import I17 from '../assets/images/17.JPG';
import I18 from '../assets/images/18.JPG';
import I19 from '../assets/images/19.JPG';
import I20 from '../assets/images/20.JPG';
import TGBF from '../assets/docs/TGBF.pdf';



function tgbf() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

      <table>
	  
		
		<tr>
			<td>
				<p class="imagecenter"> <img  src={logo}  alt="Territory Gold logo" /></p>
			</td>
			<td>
				<h1>2022 Territory Gold Bridge Festival</h1>
				<p ><a href="https://www.abfevents.com.au/events/results/results.asp?yr=2022&dir=tg">Results Page</a></p>
			</td>
		</tr>
		
		

		<tr>
			<td>
				<h3 class="right">Winner MatchPoint Pair Final</h3>
				<p> </p>
				<h3 class="right">G. Kozakos and P. Gue</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I01}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd MatchPoint Pair Final</h3>
				<p> </p>
				<h3 class="right">R Ellaway and K Ellaway</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I02}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd MatchPoint Pair Final</h3>
				<p> </p>
				<h3 class="right">B Nash and J Wallis</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I03}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">1st Plate A MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">J Rasmussen and T Haubrick</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I04}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Plate A MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">B Mill on behalf of A Mill and A Malusish</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I05}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd Plate A MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">W Whiting and S Murray-White</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I06}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">1st Plate B MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">O Owen and N Demytko</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I07}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Plate B MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">P Nunn and P Buck</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I08}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd Plate B MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">M Bloom and S Harrison</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I09}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Restricted Winner MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">E Boocock and P Hassall</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I10}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Highest other NT Pair MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">L Black and D Hnaris(absent)</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I11}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Winner Swiss Teams</h3>
				<p> </p>
				<h3 class="right">S Hinge G Kozakos J Haffer and P Gue</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I12} alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Swiss Teams</h3>
				<p> </p>
				<h3 class="right">J Martin L Martin A Dawson and D Christian</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I13}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd Swiss Teams</h3>
				<p> </p>
				<h3 class="right">M Courtney C Despaquale T Brown and S Ingham</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I14}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Restricted Winner Swiss Teams</h3>
				<p> </p>
				<h3 class="right">M Kelham P Hassal E Boocock and D Hood</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I15}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Winner Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">G Kozakos and P Gue</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I16}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">B Taranto and M Doust</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I17}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">S Hinge and J Haffer</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I18} alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">1st Restricted Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">D Owen and N Demytko</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I19}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Restricted Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">B Campbell and H Cook</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I20}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
	</table>

      </div>


      
      <Footer/>
    </div>
  );
}

export default tgbf;