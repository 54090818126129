import '../App.css';
import {Link} from 'react-router-dom';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import logo from '../assets/images/tgbflogotrans.gif';
import I01 from '../assets/images/TGBF2023/01.jpg';
import I02 from '../assets/images/TGBF2023/02.jpeg';
import I03 from '../assets/images/TGBF2023/03.MP.jpg';
import I10 from '../assets/images/TGBF2023/10.jpg';
import I12 from '../assets/images/TGBF2023/12.jpg';
import I13 from '../assets/images/TGBF2023/13.jpg';
import I14 from '../assets/images/TGBF2023/14.jpg';
import I15 from '../assets/images/TGBF2023/15.jpg';
import I16 from '../assets/images/16.JPG';
import I17 from '../assets/images/TGBF2023/17.jpeg';
import I18 from '../assets/images/TGBF2023/18.jpg';
import I19 from '../assets/images/TGBF2023/19.jpg';
import I20 from '../assets/images/TGBF2023/20.jpg';
import TGBF from '../assets/docs/TGBF.pdf';



function tgbf() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

      <table>
	  
		
		<tr>
			<td>
				<p class="imagecenter"> <img  src={logo}  alt="Territory Gold logo" /></p>
			</td>
			<td>
				<h1>2023 Territory Gold Bridge Festival Editing!!</h1>
				<p ><a href="https://www.abfevents.com.au/events/results/results.asp?yr=2023&dir=tg">Results Page</a></p>
			</td>
		</tr>
		
		

		<tr>
			<td>
				<h3 class="right">Winner MatchPoint Pair Final</h3>
				<p> </p>
				<h3 class="right">Jake Andrews and Francis Lyons</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I01}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd MatchPoint Pair Final</h3>
				<p> </p>
				<h3 class="right">Justin Williams and Attilio De Luca</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I02}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd MatchPoint Pair Final</h3>
				<p> </p>
				<h3 class="right">Betty Mill and Jonathon Free</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I03}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Restricted Winner MatchPoint Pair</h3>
				<p> </p>
				<h3 class="right">Diane Hood and Neil Williams(absent)</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I10}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Winner Swiss Teams</h3>
				<p> </p>
				<h3 class="right">Caprice Davey, Justin Williams, Jon Hunt and Sheila Bird</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I12} alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Swiss Teams</h3>
				<p> </p>
				<h3 class="right">Geoff Holman, Chris Ingham, Don Allen and David Matthews</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I13}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd Swiss Teams</h3>
				<p> </p>
				<h3 class="right">Peter Watson, Julia Watson, Michael Byrne and Williams Van Bakel</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I14}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Restricted Winner Swiss Teams</h3>
				<p> </p>
				<h3 class="right">Diane Hood, Michael Hewett, Stephanie King and Neil Williams (absent)</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I15}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">Winner Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">George Kozakos and Phil Gue</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I16}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">2nd Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">Sheila Bird and Felicity Smyth</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I17}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">3rd Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">Dave Munro and Gwyneira Brahma</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I18} alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		<tr>
			<td>
				<h3 class="right">1st Restricted Swiss Pairs</h3>
				<p> </p>
				<h3 class="right">Unknown is this the picture???
				Two pictures but don't know which prize</h3>
			</td>
			<td>
				<p class="imagecenter"><img  src={I19}  alt="Territory Gold Winner" width="300" height="300"/></p>
				<p class="imagecenter"><img  src={I20}  alt="Territory Gold Winner" width="300" height="300"/></p>
			</td>
		</tr>
		
	</table>

      </div>


      
      <Footer/>
    </div>
  );
}

export default tgbf;