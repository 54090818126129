import '../App.css';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';



function tc() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

      <p><span class="style3">The NTBA</span> is a not-for-profit association that supports and promotes bridge in the Northern Territory on behalf of its members - the three affiliated NT bridge clubs.</p>

<p align="justify">The affiliated clubs direct the association through their membership of Committee.</p>

<p align="justify">The Association's policy is not to compete with its member clubs  - rather it supports beginner and other lessons. </p>

<p align="justify">Through its Tournament Committee, it organises Championship-level competitions open to all NT playing members (and visitors). It also convenes the annual Territory Gold Bridge Festival, held alternately in Darwin and Alice Springs.</p>



<table width="77%" border="0" align="left" bgcolor="#FFFF99">

  <tr>

    <td width="27%"><b>Tournament Committee:</b></td>

    <td width="50%"><b>Mairead Kelly (Chair)</b></td>

    <td width="23%"><strong>0427 000 923 </strong></td>

  </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Pam Nunn </b></td>

    <td>&nbsp;</td>

  </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Dimitri Hnaris</b></td>

    <td>&nbsp;</td>

  </tr>

  <tr>

    <td>&nbsp;</td>

    <td><b>Rosemary Mooney </b></td>

    <td>&nbsp;</td>

  </tr>
  <tr>

    <td>&nbsp;</td>

    <td><b>Alexandra Wilson</b></td>

    <td>&nbsp;</td>

  </tr>

</table>

<p>&nbsp;</p>

      </div>


      
      <Footer/>
    </div>
  );
}

export default tc;