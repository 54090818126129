import play from "../assets/images/playtime.jpg"

function Image(){
  return (
    <div className="right">
      <img src={play} alt="Bridge - come and try it!" width="508" height="343" border="0" />
    </div>)
}

export default Image;
