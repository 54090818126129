import '../App.css';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';



function role() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

<p><span class="style3">The NTBA</span> is a not-for-profit association that supports and promotes bridge in the Northern Territory on behalf of its members - the three affiliated NT bridge clubs: Arafura, Darwin and Alice Springs. </p>

<p align="justify">The affiliated clubs direct the association through their membership of Committee. </p>

<p align="justify" class="style2">What does it do?</p>

<p align="justify">The NTBA  is the vital link between clubs and their players and the Australian Bridge  Federation (ABF, see below).&nbsp;Its  functions include:</p>

<ol><li class="style8">Supporting bridge in the NT by running NT  Championship events and selecting representatives for interstate events - like  the annual Grand National Open Teams and paying the entry fee (about $1,000).</li>

  <li class="style8">Providing prize money of $1500/pair to enable  the winners of the annual NT Butler Pairs Championship to attend any interstate  gold-pointed event in the following 12 months.</li>

  <li class="style8">Providing $500 annually to allow an Alice  Springs pair to participate in an event as above or similar event in Darwin.</li>

  <li class="style8">Providing $450 to each member of an NT team competing  in the annual Australian National Championships and paying their entry fee.</li>

  <li class="style8">Representing the NT on the ABF Council (which  has 2 Councillors from each State and Territory) and contributing to the work  of its numerous committees.</li>

  <li class="style8">Running the annual <span class="content">Territory Gold Bridge  Festival</span> (under licence from the ABF), allowing all Territory players to meet  their interstate bridge-playing friends at reasonable cost.</li>

  <li class="style8">Running weekly Saturday Bridge sessions to  encourage entry to its red-pointed Championship and selection events.</li>

</ol>

<p>To do these things, it raises money from its affiliate clubs  in the form of affiliation fees (normally $18 per capita per annum).&nbsp; This is the only regular income, as it aims  to run Saturday Bridge on a break-even basis.&nbsp;  The above expenditures are sustainable only because the Territory Gold  Bridge Festival normally runs at a profit.</p>



<p align="justify"><span class="style2">What does the Australian Bridge Federation do?</span></p>

<p align="justify">The ABF raises money from clubs in the form of annual capitation  fees and also from the Masterpoint  system.&nbsp; The benefits for players are:</p>

<ol>

  <li class="style8">Comprehensive Public  Liability and Volunteer Worker insurance for all affiliated clubs.</li>

  <li class="style8">A Masterpoint scheme for all affiliated clubs  (billing them directly for the service).</li>

  <li class="style8">On application by clubs, direct  financial support for marketing and promotion, teaching, training for  directors, visiting speakers, etc.</li>

  <li class="style8">Financial assistance to enable  players from every State and Territory to attend the annual Grand National Open  Teams (free air fares) and the ANC Restricted Butler Pairs  ($2,000 value per pair).</li>

  <li class="style8">Selection and support for  Australia&rsquo;s representatives at international tournaments.</li>

  <li class="style8">Regular on-line tournaments for  all players through the <a href="https://www.abf.com.au/abf-online-games-on-bbo/"><span class="link">BBO website</span></a></li>

</ol>

</div>


      
      <Footer/>
    </div>
  );
}

export default role;