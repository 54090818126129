import '../App.css';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';



function how() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

<p align="justify">Darwin Bridge Club runs two eight week beginner lessons per year.  Email the club for details. <a href="mailto:darwinbridgeclub@gmain.com" class="link"><b>darwinbridgeclub@gmail.com</b></a></p>

<p align="justify"><a href="http://www.abf.com.au/teaching-and-learning/getting-started/" class="link">Introductory material and online lessons  </a> are also available from the Australian Bridge Federation free of charge.</p>

<p align="justify">And don't forget, it's FUN!<span class="style5"><br />

</span></p>

</div>


      
      <Footer/>
    </div>
  );
}

export default how;