import logo from "../assets/images/ntba_logo.GIF"

function Heading(){
  return (
    <div className="header">
    <h1 align="center">Northern Territory Bridge Association</h1>
    <img src={logo} width="145" height="85" border="0" alt="NTBA Logo"/>

    </div>)
}

export default Heading;
