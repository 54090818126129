import '../App.css';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';



function where() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

  <p>New players and visitors are welcome at events run by the NTBA and its affiliated clubs.</p>

  <h3>NTBA</h3>

  <blockquote>

    <h3>Sessions</h3>

    <p>Normal Saturday talks and bridge sessions are held at the Darwin Bridge Club rooms (address below) at 1.00 for 1.15pm - <strong>all</strong> players are welcome.  Play will sometimes be preceded at 12.30pm by a 20-30 minute talk to enhance your playing skills.</p>

    <p>Special event listed in the NTBA Events link will also be played at the venue.</p>

    

  </blockquote>

  <h3><span class="style4">Arafura Bridge Club</span> - <a href="http://www.bridgewebs.com/arafura/" target="club" class="link">website and results</a></h3>

  <blockquote>

    <h3>Sessions</h3>

    <p>Monday evening 6:30 for 6:45 - 10pm  and Thursday afternoon 12:45 for 1.00 - 4:30pm...at the Darwin Bridge Club rooms.</p>

    <table width="90%">

      <tr>

        <td width="15%"><b>Contacts: </b></td>

        <td width="25%">President</td>

        <td width="26%">Marion Davey </td>

        <td width="34%">0428 757 550 </td>

      </tr>

      

    </table>

  </blockquote>

  <h3><span class="style4">Darwin Bridge Club</span> - <a href="http://www.bridgewebs.com/darwin/" target="club" class="link">website and results</a></h3>

  <blockquote>

    <h3>Sessions</h3>

   <p>Partners are necessary for all sessions except Tuesday.  If you are a visitor, please contact one of the hosts and we'll endeavour to find a partner for you.</p>

   <p>DBC runs two eight week beginner lessons per year.  Email the club for details. <a href="mailto:darwinbridgeclub@gmain.com" class="link"><b>darwinbridgeclub@gmail.com</b></a></p>

   <p>Monday and Tuesday are hosted by Toni Vine-Bromley.  Friday has various hosts. Contact <a href="mailto:darwinbridgeclub@gmain.com" class="link"><b>darwinbridgeclub@gmail.com</b></a></p>

   <table width="72%" border="0">



      <tr>

        <td width="29%">Monday</td>

        <td width="26%">12.45 for 1.00pm</td>

        <td width="45%">Duplicate Pairs.</td>

      </tr>

      <tr>

        <td>Tuesday evening</td>

        <td>6.30</td>

        <td>&quot;Chatty Tuesday&quot;, finishing 9.30pm. Start with prepared Topic or Q/A</td>

      </tr>

      <tr>

        <td>1st Friday  and 3rd Friday of the month</td>

        <td>6:30 for 6.45pm</td>

        <td>Social teams event played in a social atmosphere with supper and drinks.  Registration required</td>

      </tr>

      

    </table>

    <p align="left"><b>Clubroom</b>: 43 Parkside Crescent (corner of Leanyer Drive), Leanyer - easily accessible, ground level, air-conditioned. <a href="https://www.google.com/maps/place/12%C2%B022'47.4%22S+130%C2%B054'12.2%22E/@-12.3799389,130.9022407,17.5z/data=!4m4!3m3!8m2!3d-12.379833!4d130.903383?hl=en" class="link">Location Map</a>.</p>

    <table width="91%">

      <tr>

        <td width="15%"><b>Contacts: </b></td>

        <td width="25%">President</td>

        <td width="26%">Will Proft </td>

        <td width="34%">0481 033 389</td>

      </tr>



      <tr>

        <td>&nbsp;</td>

        <td>Postal address:</td>

        <td colspan="2">PO Box 40804, Casuarina, NT 0811</td>

      </tr>

      <tr>

        <td>&nbsp;</td>

        <td>Clubroom hire </td>

        <td colspan="2"><a href="mailto:darwinbridgeclub@gmain.com" class="link"><b>darwinbridgeclub@gmail.com</b></a></td>

      </tr>

    </table>

  </blockquote>

  

  <h3><span class="style4">Alice Springs Bridge Club</span> - <a href="http://www.alicespringsbridgeclub.com.au" target="club" class="link">website and results</a></h3>

  <blockquote>

    <h3>Sessions</h3>

    <table width="100%" border="0">

      <tr>

        <td width="18%">Tuesday morning</td>

        <td width="18%">10.00am</td>

        <td width="64%"> Duplicate until about 1.30pm, no partner required. </td>

      </tr>

      <tr>

        <td>Thursday evening*</td>

        <td>7.00pm </td>

        <td>Duplicate. </td>

      </tr>

      <tr>

        <td>Saturday afternoon</td>

        <td>1.00pm</td>

        <td>Duplicate, till about 4.30pm. </td>

      </tr>
      <tr>

        <td>Sunday fternoon</td>

        <td>4pm</td>

        <td>Supervised play very casual till about 6pm. </td>

      </tr>

    </table>

    <p>All sessions are held at the Over 50s club room, Wills Terrace (opposite the Todd Tavern, at the northern end of Todd Mall). Players younger than 50 also welcome! For duplicate sessions, please arrive before start time. </p>

    <p>* Partners are necessary at these sessions so, if you're a visitor, please ring 8952 4061 (Eileen) or 8952 3280 (Stephanie) and we'll endeavour to find you one!</p>

    <p>&nbsp;</p>

    <table width="90%">

      <tr>

        <td width="15%"><b>Contacts: </b></td>

        <td width="25%">President</td>

        <td width="26%">Phil Hassall</td>

        <td width="34%">0417 819 220</td>

      </tr>

      <tr>

        <td>&nbsp;</td>

        <td>Secretary</td>

        <td>Eleanor Diflo  </td>

        <td class="link"><a href="mailto:secretary@alicespringsbridgeclub.com.au"></a></td>

      </tr>

      <tr>

        <td>&nbsp;</td>

        <td>Treasurer</td>

        <td>Michael Hewett </td>

        <td>&nbsp;</td>

      </tr>

      <tr>

        <td>&nbsp;</td>

        <td>Tournament Director</td>

        <td>Denise McEwan</td>

        <td>8952 3280 </td>

      </tr>

      <tr>

        <td><b>Postal address:</b></td>

        <td colspan="3">PO Box 2759, Alice Springs, NT, 0871.</td>

      </tr>

    </table>

  </blockquote>

</div>



      
      <Footer/>
    </div>
  );
}

export default where;