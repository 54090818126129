import '../App.css';
import {Link} from 'react-router-dom';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import Better from '../assets/docs/BetterTogether.pdf';


function notices() {
  return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      <div id="content">

      <div id="noticescontent">

      

      <h2>Relaunch of <a href="https://www.ronklingerbridge.com/">RonKlingerBridge.com</a></h2>

      <p>The RonKlingerBridge.com website has been relaunched and is aimed at improving every bridge player's game. To celebrate the launch, there is a new premium member offer to subscribe for $49 per year (less than 20c per column) up to July 31. The subscription price will then increase to $59 per year. Publication of content will commence on 3 July, 2022.</p>

      <p>Paid members enjoy the full benefits of the platform, including five emails a week of bridge columns, as well as access to the archive of previous bridge columns from 2022 onwards. For more details, please check out our updated website, www.ronklingerbridge.com or visit ronklinger.substack.com to join.</p>


        <hr/>

      <p>Allison Stralow, President of ABF, <Link to={Better} target="_blank"> message and thanks to all volunteers in our bridge clubs</Link> </p>


    </div>

      </div>


      
      <Footer/>
    </div>
  )
}


export default notices;