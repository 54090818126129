import '../App.css';
import {Link} from 'react-router-dom';
import Heading from '../components/heading';
import Navigation from '../components/navigation';
import Image from '../components/imagemain';
import Footer from '../components/footer';
import Notices from '../pages/notices';


function main (){
    return (
    <div className="wrapper">
      <Heading />
      <Navigation />
      
      <div id="content">

      <p align="left"><span className="style3">Bridge</span> is the world&rsquo;s most challenging mental sport - a game of skill, communication and infinite possibilities. It&rsquo;s part mathematics, part logic, part reason, and embodies cooperation and problem-solving (though a little luck also helps). It is an easy game to learn, a hard game to master and you don&rsquo;t have to be an expert to enjoy it!</p>

      <p align="left">Bridge is one of the few games played today by people of all ages and nationalities.</p>

      <p align="left">While bridge is a partnership game, most clubs also host &quot;just come along&quot; sessions.</p>

      <hr />

      <p>You will have noticed that to get to this website you now have to use the name ntba.org.au.  When this website was first developed there were no domain names for organisaitons so we had to use a business domain name .com.au.  However we are not a business and increasingly it has been more difficult to renew our registration beacuse we were asked for documents that we simply don't possess.</p>
      <p> Eventually we decided to release our .com.au domain name and get a new one .org.au which is more appropriate to the type of this organisation.  We are sorry for any inconvenience.</p>

      

      </div>


      <Image />
      <Footer/>
    </div>
    );
}

export default main;